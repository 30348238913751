.main-navs .menu-list {
    padding: 25px 25px 25px 25px;
}

.sidebar-callapsed {
    .menu-list {
        padding: 25px 25px 25px 20px;
    }
}

.sub-menu-items .menu-item {
    .menu-item-container {
        padding: 13px 16px 15px 18px;
    }
}

.submenu-title {
    display: flex;

    .menu-item-container {
        display: block;
        padding: 18px 15px 18px 5px;
        color: #000;
        border-left: 2px solid transparent;
        height: unset;
    }

    .icon-container {
        svg {
            color: #0E6BA8;
        }
    }

    &.collapsed-nav:hover,
    &.collapsed-nav:focus,
    &.collapsed-nav:focus-within {
        .icon-container {
            background-color: #9DE0F8;

            svg {
                color: #0E6BA8;
            }
        }
    }
}

// unsettiing a height, set on other sidebar
.sidebar .menu-list .menu-item .menu-item-container {
    height: unset;
}

.menu-item {
    .menu-item-container {
        display: block;
        padding: 18px 15px 18px 5px;
        color: #000;
        border-left: 2px solid transparent;
        border-radius: 0.25rem;
    }

    .icon-container {
        margin-right: 14px;

        svg {
            color: #0E6BA8;
        }
    }

    &.collapsed-nav:hover,
    &.collapsed-nav:focus,
    &.collapsed-nav:focus-within {
        .icon-container {
            background-color: #9DE0F8;

            svg {
                color: #0E6BA8;
            }
        }
    }

    .active {
        .menu-item-container {
            background-color: #0E6BA8;
            color: #fff;
            border-left: 2px solid #0E6BA8;

            @media (max-width: 479.8px) {
                background-color: none;
                color: #000;
                border-left: 0px solid transparent;
            }
        }

        .icon-container {
            background-color: #0E6BA8;

            svg {
                color: #fff;
            }
        }
    }

    &.collapsed-nav .active {
        .menu-item-container {
            background-color: #fff;
            color: #000;
            border-left: 2px solid transparent;
        }
    }


    &:hover:not(.collapsed-nav),
    &:focus:not(.collapsed-nav) {
        .menu-item-container {
            background-color: #9DE0F8;
            border-left: 2px solid #0E6BA8;
            color: #000;

            .icon-container {
                background-color: #9DE0F8;

                svg {
                    color: #0E6BA8;
                }
            }

            @media (max-width: 479.8px) {
                background-color: transparent;
                border-left: 0px solid transparent;
            }
        }
    }

    &:focus,
    &:focus-within {
        .menu-item-container {
            background-color: #9DE0F8;
            border-left: 2px solid #0E6BA8;
            color: #000;

            .icon-container {
                background-color: #9DE0F8;

                svg {
                    color: #0E6BA8;
                }
            }
        }
    }
}

.main-navs .menu-list .menu-item,
.main-navs .menu-list .submenu-title {
    width: 100%;

    .icon-container {
        border-radius: 5px;
    }

    .menu-item-container {
        display: flex;
        width: 100%;
    }
}