/* Payment Progress Thermometer Styles */

/* Payment Inquiry Link Styles */
.payment-inquiry-link {
  font-size: 0.9rem;
  transition: color 0.2s ease, opacity 0.2s ease;
}

.payment-inquiry-link.disabled {
  pointer-events: none;
}
.progress-details {
  margin-top: 2rem;
}

.payment-progress-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.payment-details {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e9ecef;
  font-size: 0.9rem;
}

.payment-amount, .session-status {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: #f1f1f1;
}

.progress-container {
  position: relative;
  margin: 1rem 0;
  padding: 12px 0;
  width: 100%;
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.progress-step-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2; /* Above the lines */
}

.progress-step-indicator {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  margin: 4px 0 10px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 2; /* Make sure indicators appear above the line */
}

.progress-step.completed .progress-step-indicator {
  background-color: #28a745;
  border-color: #28a745;
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.progress-step-indicator i {
  color: #777;
  font-size: 14px;
}

.progress-step.completed .progress-step-indicator i {
  color: white;
}

/* Remove dot indicators as we're using icons now */
.progress-step-indicator::after {
  display: none;
}

.progress-step.completed .progress-step-indicator::after {
  display: none;
}

.progress-step.clickable {
  cursor: pointer;
  transition: transform 0.2s;
}

.progress-step.clickable:hover {
  transform: scale(1.05);
}

.progress-step.clickable .progress-step-indicator {
  position: relative;
}

.progress-step.clickable .fa-download {
  color: white;
  font-size: 14px;
  z-index: 3;
}

.progress-step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 8px;
  max-width: 130px;
  word-break: break-word;
}

.progress-step-label {
  font-size: 0.85rem;
  color: #6c757d;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 4px;
}

.progress-step.completed .progress-step-label {
  color: #28a745;
  font-weight: 600;
}

.progress-step-subtext {
  font-size: 0.8rem;
  color: #495057;
  font-style: italic;
  text-align: center;
  max-width: 130px;
}

.progress-step:first-child .progress-step-subtext {
  font-weight: 600;
  font-style: normal;
  color: #212529;
}

/* Making the progress line green when steps are completed */
.progress-step.completed .progress-step-indicator {
  border-color: #28a745;
}

/* Create the segments between the dots */
.progress-steps::before {
  content: "";
  position: absolute;
  top: 19px; /* Adjusted to center with the step indicator */
  left: calc(16px + 5%); /* First dot center + offset */
  right: calc(16px + 5%); /* Last dot center + offset */
  height: 3px;
  background-color: #e9ecef;
  z-index: 0; /* Below the step indicators */
}

/* Create green segments when steps are completed */
.progress-steps::after {
  content: "";
  position: absolute;
  top: 19px; /* Match with the gray line */
  left: calc(16px + 5%); /* Match with gray line */
  width: 0; /* Dynamic width based on completed steps */
  height: 3px;
  background-color: #28a745;
  z-index: 0; /* Below the step indicators */
  transition: width 0.3s ease;
}

/* Determine width based on completed steps */
.progress-steps.step-1-complete::after { width: calc(33% - 10%); }
.progress-steps.step-2-complete::after { width: calc(66% - 10%); }
.progress-steps.step-3-complete::after { width: calc(90% - 32px); }

.total-earnings-callout {
  background-color: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #28a745;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  font-size: 1.2rem;
}

.session-status-container {
  background-color: #f8f9fa;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  margin-bottom: 0.8rem;
  display: inline-block;
}

.session-status-label {
  font-weight: bold;
  margin-right: 0.3rem;
}

.session-status-value {
  color: #495057;
}

.payment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.payment-date {
  background-color: #e9ecef;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-weight: 500;
}

.date-label {
  font-weight: bold;
  margin-right: 0.3rem;
}

.date-value {
  color: #495057;
}

.session-title {
  font-size: 1.1rem;
  text-align: right;
  flex: 1;
  padding-left: 1rem;
}

@media (max-width: 768px) {
  .payment-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .payment-date {
    margin-bottom: 0.5rem;
  }
  
  .session-title {
    text-align: left;
    padding-left: 0;
  }
}
