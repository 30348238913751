.day-limitations-msg {
  margin-bottom: 20px;

  span {
    color: rgb(94, 36, 36)
  }
}

.warn-msg {
  background-color: #fff3cd;
  padding: 10px;
  border-radius: 4px;

  &.warn-msg-red {
    background-color: #ffe6e6;
    border: 1px solid #ffcccc;
  }

  &.warn-msg-success {
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    span {
      color: #155724;
    }
  }
}

.end-adornment {
  margin-right: 14px;
  white-space: nowrap;
  
  @media (max-width: 768px) {
    display: none;
  }
}

.end-adornment-normal {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
}

.end-adornment-bold {
  color: #dc3545;
  font-weight: bold;
}