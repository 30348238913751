a {
	color: #0f6aa8;

	a:hover {
		color: #0099d3;
	}
}

h2 {
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 1.3;
	color: #32325d;
}

.op-loading {
	animation: Loading-spin infinite 0.7s linear;
}

@keyframes Loading-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.btn_flat {
	background: transparent;
	border: 0;
	color: $blue;
}

.title-link {
	color: $primary;
	font-size: 20px;

	&:hover {
		text-decoration: underline;
	}
}

.main-container {
	min-height: 700px;

	@media (min-width: 480px) {
		padding-top: 22px;
	}

	.page-topic {
		color: $black;
		font-size: 22px;
		font-weight: bold;
		cursor: pointer;

		&.title-link {
			color: $primary;
			font-size: 20px;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.main-tabs {
		button {
			margin-top: 5px !important;
		}
	}

	.v-center {
		display: flex;
		align-items: center;
	}
}

.header-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	.header-actions {
		flex: 1;
		text-align: right;
	}
}

.checkbox-col {
	max-width: 30px;
}

.margin0 {
	margin: 0;
}

.ml0 {
	margin-left: 0;
}

.general-container {
	padding: 10px 0;
	padding-bottom: 50px;
}

@media (min-width: 991.98px) {
	.general-container {
		padding: 10px 0;
		padding-bottom: 450px;
	}
}

.loading-container {
	padding: 20px;
}

.nolist-placeholder {
	padding: 20px 20px 0 20px;

	span {
		font-size: 20px;
		color: lightgray;
	}
}

.list-error {
	span {
		color: red;
	}
}

.highlight-box {
	background: #ffffff;
	border: 1px solid rgba($teal, 0.49);
	box-shadow: 10px 12px 14px $theme-shadow;
	border-radius: 16px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	overflow: "hidden";
}

.session-item {
	&:hover {
		background: white;
	}

	.session-item-title {
		span {
			font-size: 16px;
			color: black;
		}
	}

	.session-item-tags {
		display: flex;
		flex-wrap: wrap;
		margin: 5px 0;

		.session-item-tag {
			margin: 5px 0;
			margin-right: 10px;
			padding: 5px 10px;
			border-radius: 5px;
		}
	}

	.blue-tag {
		background: lightblue;
	}

	.green-tag {
		background: lightgreen;
	}

	.pink-tag {
		background: lightpink;
	}

	.darkred-tag {
		background: #cc99aa;
	}

	.yellow-tag {
		background: #cbdc60;
	}

	.brown-tag {
		background: #ffa600;
	}

	.red-tag {
		background-color: rgb(255, 104, 104);
	}
}

.contact-item {
	width: 100%;
	padding: 10px 20px;
	background: $white;
	box-shadow: 10px 12px 24px $theme-shadow;
	border: 1px solid rgba($teal, 0.49);
	border-radius: 10px;
	margin-bottom: 5px;
	cursor: pointer;

	.contact-info {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 5px;

		&> :not(:first-child) {
			margin-left: 5px;
		}
	}

	&:hover {
		background: #f7f7f7;
	}

	.name {
		font-size: 20px;
		color: black;
		font-weight: bold;
	}

	.phone {
		padding: 3px 10px;
		background: lightgreen;
		border-radius: 5px;
		font-size: 16px;
		color: black;
		cursor: pointer;

		&:hover {
			background: rgb(119, 196, 119);
		}
	}

	.school {
		padding: 3px 10px;
		background: lightcoral;
		border-radius: 5px;
		font-size: 16px;
		color: black;
		cursor: pointer;

		&:hover {
			background: rgb(132, 164, 175);
		}
	}

	.credits {
		padding: 3px 10px;
		background: lightgreen;
		border-radius: 5px;
		font-size: 16px;
		color: black;
		cursor: pointer;

		&:hover {
			background: rgb(132, 164, 175);
		}
	}

	.email {
		padding: 3px 10px;
		background: lightblue;
		border-radius: 5px;
		font-size: 16px;
		color: black;
		cursor: pointer;

		&:hover {
			background: rgb(132, 164, 175);
		}
	}

	.booked-sessions {
		padding: 3px 10px;
		background: lightpink;
		border-radius: 5px;
		font-size: 16px;
		color: black;
		cursor: pointer;

		&:hover {
			background: rgb(205, 146, 155);
		}
	}
}

.multicheck-container {
	margin-bottom: 10px;

	.multicheck {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.multicheck-item {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			min-width: 2.5em;
			padding: 0.5em 0.75em;
			height: 2.5em;
			margin: 0.2em;
			background: $secondary;
			border-radius: 0.625em;
			cursor: pointer;
			color: $black;

			&:hover {
				background: darken($secondary, 10%);
			}

			&.item-checked {
				background: $primary;
				color: $white;
			}
		}
	}
}

.search-session {
	margin-top: 10px;
	padding: 5px 10px;
	background: #f0f0f0;
	border-radius: 5px;
	cursor: pointer;

	i {
		color: #f0f0f0;

		&.active {
			color: red;
		}
	}

	&:hover {
		background: #dedede;

		i {
			color: #dedede;
		}

		i.active {
			color: red;
		}
	}

	.title {
		font-size: 16px;
		color: black;
	}
}

.tag-session {
	margin-top: 10px;
	padding: 5px 10px;
	background: #f0f0f0;
	border-radius: 5px;
	cursor: pointer;

	&:hover {
		background: #dedede;
	}

	.title {
		font-size: 16px;
		color: black;
	}
}

.session-detail-session-image {

	// position: relative;
	// width: 400px;
	// height: auto;
	// margin-bottom: 32px;
	img {
		max-width: 100%;
		height: auto;
		border-radius: 20px;
	}
}

.session-detail-provider-image {
	position: relative;
	width: 300px;
	height: auto;

	img {
		width: 100%;
		height: 100%;
		border-radius: 20px;
	}
}

.modal-body .session-info {
	.session-image {
		position: relative;
		width: 200px;
		height: 200px;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

.sidebar-icon-wrap {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 16px;

	&>i,
	&>img {
		width: 30px;
		height: 30px;
		font-size: 30px;
		color: #0E6BA8;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& span {
		color: #32325d;
		margin-top: 2px;
	}

	@include media-breakpoint-down(lg) {
		flex-wrap: wrap;
	}
}

.session-sidebar {
	order: 1;

	@include media-breakpoint-down(md) {
		order: 2;
	}
}

.session-bio {
	order: 2;

	@include media-breakpoint-down(md) {
		order: 1;
	}
}

.side-bar .session-info-item {
	margin-bottom: 16px;

	.font-weight-bold {
		color: #32325d;
	}

}

.session-info {
	.session-info-row {
		position: relative;
	}

	.session-image {
		float: right;
		max-width: 320px;
		margin-bottom: 20px;

		img {
			width: 100%;
			height: auto;
			border-radius: 10px;
		}
	}

	.session-info-form-group {
		align-items: center;
		padding: 0 20px;
	}

	.session-info-item {
		margin-top: 10px;

		span.info-title {
			color: gray;
			font-size: 14px;
		}

		a.info-link {
			span.info-content {
				cursor: pointer;
				color: $blue-secondary;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		span.info-content {
			color: black;
			white-space: pre-line;
		}
	}
}

div.info-content {
	padding: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: white;
	font-size: 14px;

	.tag-item {
		padding: 5px 10px;
		border-radius: 5px;
		margin: 10px;
		cursor: pointer;

		span {
			color: white;
			font-size: 14px;
		}
	}

	.subject-tag {
		background: brown;

		&:hover {
			background: rgb(192, 57, 57);
		}
	}

	.grade-tag {
		background: green;

		&:hover {
			background: rgb(47, 167, 47);
		}
	}

	.default-tag {
		background: gray;

		&:hover {
			background: rgb(154, 154, 154);
		}
	}
}

.form-style {
	color: black !important;
	font-size: 14px !important;
}

.form-input {
	display: flex;
	align-items: center;
	border-width: 1px;
	border-color: lightgray;
	border-radius: 5px;
	height: 40px;
	padding: 0 10px;
	font-size: 14px;
	color: black;
}

.flex-row {
	display: flex;
	flex-wrap: wrap;
}

.flexview {
	display: flex;
	flex-direction: row;

	.orview {
		label {
			color: white;
		}

		.orspan {
			height: 42px;
			display: flex;
			align-items: center;
			padding-right: 10px;

			span {
				color: #8a8d94;
			}
		}
	}
}

.file-view {
	width: 100px;
	height: 100px;
	background-color: lightgray;
	position: relative;
	border-radius: 5px;
	margin-right: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
	margin-bottom: 20px;
	// cursor: zoom-in;

	.file-view-container {
		span {
			display: inline-block;
			width: 80px;
			white-space: initial;
			overflow: hidden;
			text-overflow: ellipsis;
			color: black;
		}

		img {
			width: 100px;
			height: 100px;
			border-radius: 5px;
		}
	}

	.btn-remove {
		position: absolute;
		top: -10px;
		right: -10px;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: $red;
		cursor: pointer;
		padding-left: 6px;
		padding-top: 1px;
		font-size: 12px;
		color: black;

		&:hover {
			background-color: #fe8383;
		}

		&:active {
			background-color: #fc2c2c;
		}
	}

	.btn-download {
		position: absolute;
		top: -10px;
		right: 15px;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: #60d8ab;
		cursor: pointer;
		padding-left: 4px;
		padding-top: 2px;
		font-size: 12px;
		color: black;

		&:hover {
			background-color: #7ae0bb;
		}

		&:active {
			background-color: #41d49e;
		}
	}
}

.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.4;
	background-color: black;
	border-radius: 4px;
}

.acknowledgements {
	color: #0f6aa8;
	cursor: pointer;
}

button.modal-action {
	background-color: transparent;
	border: 0;
	padding: 0;
	width: 40px;
}

.photo-session-filter {
	margin-bottom: 10px;
}

.photo-session-container {
	margin-top: 10px;
	-moz-column-count: 3;
	-moz-column-gap: 10px;
	-webkit-column-count: 3;
	-webkit-column-gap: 10px;
	column-count: 3;
	column-gap: 16px;

	.photo-session-item {
		display: inline-block;
		width: 100%;
		margin-bottom: 16px;
		border: 4px solid transparent;

		img {
			width: 100%;
			height: auto;
			cursor: pointer;

			&:hover {
				border: 4px solid #5e72e4;
			}

			&:active {
				border: 4px solid #3a478e;
			}
		}
	}
}

.session-loading {
	width: 100%;
	text-align: center;
	margin-top: 20px;
}

.short-desc {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: #525f7f;
	margin: 10px 0 0 0;
}

.tag-group {
	margin-top: 15px;

	span {
		font-size: 20px;
		font-weight: bold;
	}
}

.favorite {
	.subject-name {
		font-size: 20px;
		font-weight: 700;
		color: #525f7f;
	}

	.favorite-session {
		margin-top: 5px;
		padding: 5px 10px;
		background: #f0f0f0;
		border-radius: 5px;
		cursor: pointer;

		&:hover {
			background: #dedede;
		}

		.title {
			font-size: 16px;
			color: black;
			font-weight: bold;
		}
	}
}

i.favorite {
	color: $red;
}

.review {
	color: $black;
	margin: 5px auto;
	max-width: 670px;
}

.toasts-container {
	display: block !important;

	.toast {
		padding: 10px 15px;
	}
}

.quotecallout {
	margin-top: 28px;
	margin-bottom: 28px;
	padding: 32px;
	border-left-style: none;
	border-radius: 10px;
	background-color: #e8edf4;
	color: #000;
	font-size: 18px;
	line-height: 1.5;
	font-weight: 500;
}

.session-warning {
	margin: 20px 0;
	background: #d81463;
	padding: 8px 16px;
	border-radius: 8px;

	span {
		color: #e5e5e5;
		font-size: 14px;
	}
}

.indigenous-group {
	margin: 20px 0 5px 0;

	span {
		font-weight: bold;
		font-size: 24px;
		color: black;
	}
}

.session-grid {
	display: grid;
	grid-auto-rows: 1fr;
	gap: 20px;

	@include media-breakpoint-down(md) {
		grid-gap: 20px;
		gap: 20px;
		grid-auto-rows: auto;
	}
}

.tabs {
	margin-bottom: 20px;
}

.tabs {
	margin-bottom: 20px;
}

.nav-link:hover,
.nav-link.active {
	color: #0099d3;
}

h1 {
	@media (max-width: 768px) {
		line-height: 1.2;
		margin-top: 12px;
	}
}

.pending-tabs {
	.nav-item {
		cursor: pointer;
	}
}

.contact-tabs {
	margin-bottom: 1rem;

	.nav-item {
		cursor: pointer;
	}
}

.resource-tabs,
.upcoming-tabs,
.rate-tabs,
.myinfo-tabs,
.curriculum-tabs {
	display: flex;
	margin-top: 1.5rem;
	margin-bottom: 20px;

	.nav-item {
		cursor: pointer;
		display: flex;
		align-items: flex-end;
		flex: 1;
	}

	.nav-link {
		border: none;
		border-bottom: 4px solid $secondary;
		text-align: center;
		padding-bottom: 10px;
		flex: 1;
	}

	.nav-link.active {
		background: transparent;
		border-bottom: 4px solid #0d1120;
	}

	@media (max-width: 500px) {
		margin-bottom: 15px;
	}
}

.upcoming-tabs {
	margin-bottom: 60px;
}

.curriculum-tabs {
	width: 100%;
}

@media (min-width: 1000px) {
	.session-item .row {
		width: 100%;
	}
}

@media (max-width: 1000px) {
	.session-item {
		flex: 1 1;
		flex-direction: row;
		width: 100%;
		gap: 35px;
		background: white;
		box-shadow: 10px 12px 24px $theme-shadow;
		overflow: hidden;
		border: 1px solid rgba($teal, 0.49);
		border-radius: 10px;
		margin-bottom: 5px;
		padding-bottom: 20px;
		cursor: pointer;
		align-items: flex-start;
	}

	.session-item-header-container {
		display: flex;
		gap: 20px;
		justify-content: space-between;
	}

	.session-image-wrap {
		position: relative;
		overflow: hidden;
		align-self: stretch;
		height: 200px;
		min-height: 100%;

		.session-image {
			object-fit: cover;
		}

		.session-date-wrap {
			position: absolute;
			top: 0;
			left: 15px;
			z-index: 5;
		}

		.session-card-media {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 100%;
			position: absolute;
		}

		.session-date {
			background: $secondary;
			border-radius: 10px 0px;
			padding: 10px 15px;
			padding-right: 20px;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			margin-bottom: 2px;

			&:first-child {
				border-bottom-right-radius: 10px;
			}

			&:not(:first-child) {
				border-bottom-right-radius: 10px;
				border-top-right-radius: 10px;
			}
		}
	}

	.session-card-content {
		display: flex;

		.session-item-info {
			padding: 15px;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 12px;

			.session-item-info-header {
				font-size: 22px;
				font-weight: 700;
				line-height: 130%;
				color: #0d1120;
				padding: 5px 0;
			}

			.session-item-info-subheader {
				font-weight: 700;
				font-size: 16px;
				line-height: 130%;
				color: #0d1120;
			}

			.host-tag {
				font-weight: 400;
				font-size: 16px;
				color: #0d1120;

				padding-bottom: 5px;
			}

			.credits-tag {
				margin-top: 15px;
				margin-bottom: 15px;
			}

			.title-tag {
				font-weight: 700;
				color: #0d1120;
			}

			.teacher-tag,
			.school-tag {
				font-weight: 400;
				font-size: 16px;
				color: #0d1120;
			}

			.host-tag-linked {
				font-weight: 400;
				font-size: 16px;
				color: #0f6aa8;

				padding-bottom: 5px;
				text-decoration: underline;
			}
		}

	}
}

.session-search-card-container {
	padding-top: 1rem;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.session-card-button-group {
	display: flex;
	gap: 10px;
	justify-content: flex-start;
	align-items: flex-end;
	margin-top: auto;
	flex-wrap: wrap;
	padding: 0 0 20px 0;
	flex: 1;

	@media (max-width: 1000px) {
		padding: 0px 15px 15px 15px;
	}

	@media (max-width: 479.8px) {
		padding: 15px;
	}
}

.cancelled-msg {
	color: rgb(182, 129, 45);

	span.rebook-link {
		color: $primary !important;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}

.session-favorite {
	position: absolute;
	top: 0;
	right: 0;
	background: #fff;
	border-top-left-radius: 4px;
}

@media (max-width: 1000px) {
	.session-favorite {
		bottom: 100%;
		top: auto;
		right: 15px !important;
	}
}

@media (max-width: 1000px) {
	.session-item h3 {
		font-size: 1.5rem;
		line-height: 1.2;
		margin-top: 20px;
	}
}

@media (min-width: 1000px) {
	.session-item {
		width: 100%;
		display: flex;
		background: white;
		box-shadow: 10px 12px 14px $theme-shadow;

		border: 1px solid rgba($teal, 0.49);
		border-radius: 10px;
		margin-bottom: 5px;
		cursor: pointer;
		flex: 1;
		height: 100%;

		.dropdown-menu {
			z-index: 5;
			left: auto !important;
			right: 0 !important;
		}

		.session-image-wrap {
			width: 100%;
			overflow: hidden;
			object-fit: cover;
			margin-left: 15px;
			padding: 0;

			@include media-breakpoint-down(md) {
				max-height: 300px;
			}

			.session-image-container {
				width: 275px;
				height: 100%;
				object-fit: cover;
				padding-bottom: 0;
				padding-left: 0;
			}

			.session-card-media {
				width: 100%;
				padding-top: 0;
				padding-bottom: 0;
				padding-left: 0;
				inset: 0;
				object-fit: cover;
				height: 100%;
				position: absolute;
				border-bottom-left-radius: 10px;
				border-top-left-radius: 10px;
			}

			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;

			.session-image {
				object-fit: cover;
				top: 50%;
				left: 50%;
				transform: translate(50%, 50%);
			}

			.session-date-wrap {
				position: absolute;
				top: 0;
				left: 0px;
				z-index: 2;
				border-top-left-radius: 10px;
			}

			.session-date {
				background: $secondary;
				padding: 10px 15px;
				padding-right: 20px;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				margin-bottom: 2px;

				&:first-child {
					border-bottom-right-radius: 10px;
				}

				&:not(:first-child) {
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}

		.session-card-content {
			display: flex;

			.session-item-info {
				flex: 1 2;
				padding: 20px 0 20px 10px;
				display: flex;
				flex-direction: column;
				gap: 15px;

				.session-item-info-header {
					font-size: 22px;
					font-weight: 700;
					line-height: 130%;
					color: #0d1120;
				}

				.session-item-header-container {
					display: flex;
					gap: 20px;
					justify-content: space-between;
				}

				.session-item-info-subheader {
					font-weight: 700;
					font-size: 16px;
					line-height: 130%;
					color: #0d1120;
				}

				.host-tag {
					font-weight: 400;
					font-size: 16px;
					color: #0d1120;

					padding-bottom: 5px;
				}

				.title-tag {
					font-weight: 700;
					color: #0d1120;
				}

				.host-tag-linked {
					font-weight: 400;
					font-size: 16px;
					color: #0f6aa8;

					padding-bottom: 5px;
					text-decoration: underline;
				}

				.credits-tag {
					margin-top: 15px;
					margin-bottom: 15px;
				}

				.school-tag {
					font-weight: 400;
					font-size: 16px;
					color: #0d1120;
				}

				.teacher-tag {
					font-weight: 400;
					font-size: 16px;
					color: #0d1120;
				}
			}
		}
	}
}

.session-card {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 2rem;
	border-radius: 20px;
	background: #ffffff;
	border: 1px solid rgba($teal, 0.49);
	box-shadow: 10px 12px 24px $theme-shadow;
	text-align: center;

	@media (max-width: 500px) {
		padding: 1rem 0.5rem 1.5rem 0.5rem;
	}
}

button.react-multiple-carousel__arrow::before {
	font-size: 20px;
	color: #0e6aa7;
}

.carousel-wrap.session-detail-carousel-item.feedback {
	background: rgba(161, 232, 247, .38);
}

.session-carousel-wrap,
.carousel-wrap {
	position: relative;

	.react-multi-carousel-list {
		padding-top: 60px;
		padding-bottom: 20px;
		overflow: inherit;
		// margin-right: Min(calc(calc(calc(100vw - 100%) / -2) + 50px), -20px);
	}

	.react-multi-carousel-track {
		display: flex;
	}

	@media (max-width: 479.8px) {
		.carouselItem {
			padding-right: 0;
		}
	}


	.session-detail-carousel-item {
		padding: 2rem;
		border-radius: 20px;
		background: #ffffff;
		border: 1px solid rgba($teal, 0.49);
		box-shadow: 10px 12px 24px $theme-shadow;
		text-align: center;
		width: 100%;
	}

	.react-multiple-carousel__arrow {
		top: 0;
		border-radius: 0;
		width: 10px !important;
		height: 43px !important;
		background: $secondary;
		box-shadow: 10px 12px 24px $theme-shadow;

		&--right {
			right: 0px;
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;
		}

		&--left {
			right: 0 !important;
			left: calc(100% - 0px);
			transform: translateX(-200%);
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
		}
	}
}

.not-activated {
	pointer-events: none;
	opacity: 25%;
	cursor: not-allowed;
}

.Btn-Red:focus,
.Btn-Red:hover,
.Btn-Red:active,
.Btn-Red:visited {
	background-color: #930417;
	color: white;
}

.Btn-Red {
	border-radius: 14px;
	padding: 0.4em 1.25rem 0.5em 1.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	background: #930417;
	border: 2px solid #930417;

	&.alt {
		background: white;
		color: #930417;
		border: 2px solid #930417;
	}

	&.alt:hover {
		background: white;
		color: #930417;
		border: 2px solid #930417;
	}

	&.not-activated {
		pointer-events: none;
		opacity: 25%;
		cursor: not-allowed;
	}

	@media (max-width: 500px) {
		padding: 5px 15px;
	}
}

.Btn-Red:focus,
.Btn-Red:hover,
.Btn-Red:active,
.Btn-Red:visited {
	background-color: #930417;
	color: white;
}

.Dropdown-White,
.Dropdown-White:hover,
.Dropdown-White:focus,
.Dropdown-White:visited {
	border-radius: 14px;
	padding: 14px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: black;
	background: white !important;
	text-transform: capitalize;
}

.heart-icon:hover {
	transform: scale(1.2);
}

.dashboard-page {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	@media screen and (max-width: 997px) {
		gap: 1.5rem;
	}

	@media screen and (max-width: 450px) {
		gap: 1rem;
	}
}

.dashboard-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;

	@media screen and (max-width: 997px) {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	@media screen and (max-width: 450px) {
		gap: 1rem;
	}
}

.schoolLead-box {
	@extend .highlight-box;
	display: flex;

	justify-content: center;
	align-items: center;
	padding: 20px;

	.lead-contact {
		display: flex;
		gap: 10px;
		align-items: center;

		i {
			color: $blue-secondary;
		}
	}
}

.missingLink-box {
	@extend .highlight-box;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	gap: 1.5rem;
	text-align: center;
	align-items: center;
	padding-bottom: 1.5rem;

	@media (min-width: 481px) {
		margin-right: 2rem;
	}

	.image-box {
		width: 100%;
		aspect-ratio: 309/231;
		position: relative;
		overflow: hidden;

		img {
			min-width: 100%;
			min-height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #9de0f8;
		}
	}

	.search-link {
		cursor: pointer;

		&>span {
			text-decoration: underline;
		}
	}
}

.graph-box {
	@extend .highlight-box;
	padding: 40px 30px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	&.large-box {
		max-width: 100%;

		&>canvas {
			max-height: 250px;
		}
	}

	@media (max-width: 850px) {
		max-width: 100%;
	}
}

.pie-box {
	@extend .highlight-box;
	padding: 40px 30px;
	position: relative;
}

.wellbeing-image {
	padding-top: 0;
	top: 0;
	height: 200px;
	width: 100%;
	border-radius: 16px 16px 0 0;
}

.wellbeing-header {
	font-size: 18px;
	font-weight: 500;
	line-height: 130%;
	color: #0d1120;
	padding: 1rem 0;
	justify-content: "center";
}

.pagination {
	.page-item {
		.page-link {
			background: #9de0f8;
			color: black;
			border: #9de0f8;
		}
	}

	.page-item.active {
		.page-link {
			background: $blue-secondary;
			color: #fff;
			border: $blue-secondary;
		}
	}
}

.filter-select {
	border-radius: 1.625rem;
	background-color: $light-blue;
	border: 0;
	width: 100%;
	padding: 0.25em 1em 0.25em 0.25em;
}

.filter-dropdown {
	&:active {
		background-color: $secondary !important;
		color: black !important;
	}

	&:hover {
		background-color: $secondary !important;
		color: black !important;
	}

	background-color: $light-blue !important;
	color: black !important;
}

.pd-week {
	.session-item {
		padding: 20px;
	}
}

#root {
	min-height: 100vh;
	position: relative;
	background-color: #f3f9ff;
}

@media screen and (max-width: 768px) {
	#root {
		margin-bottom: 122px;
	}
}

.footer {
	padding: 12px 10px 14px;
}

.session-item-info-header {
	&.hyper:hover {
		text-decoration: underline;
	}
}

.ms-part {
	margin-top: 20px;

	span.title {
		color: black;
		font-weight: bold;
		text-decoration: underline;
		font-size: 16px;
	}

	.ms-part-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.pronouns,
		._2fa-selector,
		.phone-number {
			max-width: 320px;
		}

		.multicheck-item {
			padding: 5px 10px;
			background: $light;
			border-radius: 5px;
			margin: 7px;
			cursor: pointer;
			font-size: 12px;
			color: black;

			&.item-checked {
				background: $red;
				color: $white;
			}
		}
	}
}

.ms-action {
	padding-top: 20px;
	margin: auto;
	text-align: center;
}

.top-actions {
	text-align: right;
	margin-bottom: 1rem;
}

// Credit System for Sessions //

.credits {
	width: 100%;
	text-align: center;

	.credit-status {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
}

.credits-insufficient {
	width: 100%;
	text-align: center;
}

.credit-status {
	border-bottom: 1px solid $secondary;
	padding: 0.5em 0;
	margin-bottom: 2em;
	font-weight: 600;
	font-size: 1.125em;
	text-align: center;
	color: $black;
}

.fireside-item {
	gap: 20px;
}

.earn-more-card {
	margin-top: 1.5rem;
	gap: 70px;

	@media (max-width: 500px) {
		max-width: 375px;
		margin: 0 auto;
		gap: 30px;
	}
}

.collection-card {
	border-radius: 9px;
	background: #ffffff;
	border: 1px solid rgba($teal, 0.49);
	box-shadow: 10px 12px 24px $theme-shadow;
	text-align: center;
	width: 100%;
	overflow: hidden;

	.content {
		padding: 1rem 1rem 2rem 1rem;
		text-align: left;
	}

	.image-wrap {
		position: relative;
		max-height: 250px;
		overflow: hidden;

		img {
			object-fit: cover;
			object-position: center center;
			max-width: 100%;
		}
	}
}

.collection-listing {
	.image-wrap {
		position: relative;
		border-radius: 1rem;
		overflow: hidden;

		img {
			max-width: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
}

.collection-listing-list {
	border-top: 1px solid $secondary;
	padding-top: 3em;
}

.featured-collection {
	border-radius: 9px;
	background: #ffffff;
	border: 1px solid rgba($teal, 0.49);
	box-shadow: 10px 12px 24px $theme-shadow;
	text-align: center;
	width: 100%;
	overflow: hidden;
	padding-bottom: 2em;

	.image-wrap {
		position: relative;
		border-radius: 9px;
		overflow: hidden;

		img {
			object-fit: cover;
			object-position: center center;
			max-width: 100%;
		}
	}
}

.title-tag {
	i {
		color: #0f6aa8;
		font-size: 24px;
	}
}

.session-info-top-buttons {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.interview-schedule {
	min-width: 320px;
	height: 700px;
}

.carousel-container {
	overflow: hidden !important;

	li {
		padding-right: 0;
	}
}

.reschedule-session-modal {
	.modal-header {
		padding-top: 2.5rem !important;
		padding-bottom: 0 !important;
	}

	.modal-body {
		margin-bottom: 0 !important;
	}

	.modal-footer {
		justify-content: center !important;
		padding-top: 0 !important;
		padding-bottom: 2rem !important;
	}
}

.top-notify-actions {
	position: fixed;
	top: 10px;
	right: 20px;
	z-index: 1000;
	width: 52px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;

	.btn-notify {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		background-color: #36c0f0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		color: white;

		&:hover {
			width: 52px;
			height: 52px;
			border-radius: 26px;
			font-size: 26px;
		}
	}
}