@import "../../assets/scss/custom/variables.scss";

.pageHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.loadingCenterAlign {
  display: flex;
  justify-content: center;
}

.pageContent {
  width: 100%;
  margin-top: 1rem;
}

.pageLayout {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.filterResult {
  flex: 1;
}

.searchInput {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.sortWrapper {
  max-width: 280px;
  margin-top: 1rem;
}

.btnFilter {
  font-size: 24px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.sessionList {
  width: 100%;
  margin-top: 1rem;
}

.sessionsContainer {
  width: 100%;
}

.filterContainer {
  width: 100%;
}

.filterPanel {
  width: 240px;

  @media (max-width: 768px) {
    display: none;
  }
}

.filterConditions {
  width: 100%;
}

.clearFiltersButton {
  width: 100%;
  margin-top: 4px; /* Add padding above the button */
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background-color: #5e72e4; /* Same blue as primary button */
  color: white;
  border: none;
  height: 47.27px; /* Match the exact height of search input */
  border-radius: 1.625rem; /* Match the border radius of search input */
  
  &:hover {
    background-color: #324cdd; /* Darker blue on hover */
  }
  
  .filterCount {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #5e72e4;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    margin: 0 6px;
  }
}

.defaultViewHeader {
  margin: 2rem 0;
  text-align: left;
  
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .defaultViewNote {
    font-size: 1rem;
    line-height: 1.5;
    color: #666;
    max-width: 800px;
  }
}