.sivuliaqtut-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

  /* Header Styles */
  .header {
    padding: 25px 20px;
    margin-bottom: 0;
    background-image: linear-gradient(135deg, #59c6f2 0%, #c1e4f2 100%);
  }

  /* Navigation Styles */
  .sub-navigation {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 10px 0;
  }

  .nav-scroll-container {
    display: flex;
    overflow-x: auto;
    padding-bottom: 5px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .nav-scroll-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    margin: 0 5px;
    white-space: nowrap;
    border-radius: 5px;
    background-color: #d6dae2;
    border-color: #d6dae2;
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border-color: #9de0f8;
  }

  .nav-item.active {
    background-color: #59c6f2 !important;
    color: white;
  }

  .nav-icon {
    margin-bottom: 5px;
  }

  /* Content Styles */
  .main-content {
    flex: 1;
    padding: 40px 0;
    gap: 20px;
  }

  .content-section {
    scroll-margin-top: 110px;
  }

  .section-title {
    margin-bottom: 30px;
  }

  .section-title h2 {
    color: #59c6f2;
    font-weight: 600;
  }

  .section-divider {
    margin-top: 10px;
    background-color: #c1e4f2;
    height: 3px;
  }

  ul {
    list-style-position: inside;
    margin-bottom: 1rem;
  }

  /* Paragraph Styles */
  .main-content .card p {
    margin-bottom: 1.5rem !important;
  }
  
  .main-content .card-body p {
    margin-bottom: 1.5rem !important;
  }

  /* Card Styles */
  .card {
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  .card-body {
    padding: 25px;
    p {
        margin-bottom: 0;
    }
  }

  /* Standards Container */
  .standards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 20px 0;
  }

  .standard-item {
    background-color: #e9f2ff;
    padding: 15px;
    border-radius: 8px;
    flex: 1 1 300px;
    text-align: center;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  /* Image Placeholders */
  .iq-image-placeholder,
  .land-image-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-color: #f0f7ff;
    border-radius: 8px;
    padding: 20px;
  }

  /* Info and Support Boxes */
  .info-box,
  .support-box {
    background-color: #f0f7ff;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .info-icon,
  .support-icon {
    margin-bottom: 15px;
  }

  /* Footer Styles */
  .footer {
    background-color: #59c6f2;
    color: white;
    padding: 20px 0;
    margin-top: 40px;
  }

  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .header {
      padding: 40px 0;
    }

    .nav-item {
      padding: 8px 12px;
    }

    .content-section {
      padding: 30px 0;
    }

    .standards-container {
      flex-direction: column;
    }

    .info-box,
    .support-box {
      margin-top: 20px;
    }
  }

  /* Table Styles */
  .table {
    margin-bottom: 0;
  }

  .table th {
    background-color: #f0f7ff;
  }

  /* Alert Styles */
  .alert {
    border-radius: 8px;
  }

}
