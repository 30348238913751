$sidebar-width: 360px;
$sidebar-collapsed-width: 80px;
$sidebar-toggle-time: 0.4s;
$bottomnav-collapsed-height: 72px;

.layout-wrapper {
  display: flex;
  flex-direction: row;

  .main-frame {
    flex-grow: 1;
    max-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-left: 0;

    .main-content-wrapper {
      flex: 1;
    }

    @media (min-width: 480px) {
      padding-left: $sidebar-collapsed-width;
    }

    @media (min-width: 1280px) {
      transition: padding-left $sidebar-toggle-time ease;
      padding-left: $sidebar-width;
    }
  }

  .sidebar-wrapper {
    background: #fff;
    z-index: 99;
    overflow: hidden;

    @media (min-width: 480px) {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      box-shadow: 10px 12px 24px rgba(206, 222, 238, .47);
      transition: width $sidebar-toggle-time ease;
      width: $sidebar-width;
    }

    @media (max-width: 479.9px) {
      position: fixed;
      left: 0;
      right: 0;
      width: 100vw;
      bottom: 0;
      height: var(--mh);
      transition: height $sidebar-toggle-time ease;
      box-shadow: 0 -10px 24px rgba(206, 222, 238, 0.47);
    }

    @media (max-width: 379.9px) {
      height: var(--smh);
    }
  }

  .sidebar-dismiss {
    display: none;

    @media (max-width: 1279.9px) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: transparent;
      z-index: 9;
    }
  }

  ////////////////////////////////////////////////
  &.sidebar-callapsed {
    .sidebar-dismiss {
      display: none;
    }

    .main-frame {
      @media (max-width: 479.9px) {
        padding-left: 0;
      }

      @media (min-width: 480px) {
        padding-left: $sidebar-collapsed-width;
      }
    }

    .sidebar-wrapper {
      @media (max-width: 479.9px) {
        height: $bottomnav-collapsed-height;
      }

      @media (min-width: 480px) {
        width: $sidebar-collapsed-width;
      }
    }

    .sidebar-opener {
      display: flex;

      @media (min-width: 480px) {
        display: none;
      }
    }
  }
}

.sidebar {
  height: 100%;

  .sidebar-header {
    height: 80px;
    display: none;
    flex-direction: row;
    align-items: center;
    direction: rtl;

    .logo-img {
      flex: 1;

      img {
        cursor: pointer;
        margin: 0 28px;
        width: auto;
        height: 22px;
      }
    }

    .sidebar-toggler {
      cursor: pointer;
      padding: 0 28px;

      i {
        margin-top: 4px;
        color: #2B4F70;
        font-size: 28px;
      }
    }

    @media (min-width: 480px) {
      display: flex;
    }
  }

  .nav-content {
    .main-navs {
      position: relative;

      .open-bottom-nav {
        position: absolute;
        top: 0;
        left: 0;
        height: $bottomnav-collapsed-height;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 480px) {
          display: none;
        }
      }

    .nav-list {
        @media (min-width: 480px) {
          height: calc(100vh - 80px);
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        @media (max-width: 479.8px) {
          display: flex;
          flex-direction: row;
          list-style: none;
          flex-wrap: wrap;
          column-gap: 4px;
          row-gap: 8px;
          padding-left: 32px;

          li {
            width: calc(25vw - 12px);
            height: $bottomnav-collapsed-height;

            @media (min-width: 380px) {
              width: calc(20vw - 10px);
            }
          }
        }
      }

      .nav-scroll {
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        height: 32px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          height: 20px;
        }

        display: none;

        @media (min-width: 480px) {
          display: flex;
        }
      }

      .up-indicator {
        top: -8px;
        background: linear-gradient(to bottom, #ffffff 60%, #ffffffc0 65%, #ffffff60 80%, #ffffff00 100%);
      }

      .down-indicator {
        bottom: 0;
        background: linear-gradient(to top, #ffffff 60%, #ffffffc0 65%, #ffffff60 80%, #ffffff00 100%);
      }
    }

    a {
      padding: 0;
    }

    .sidebar-item {
      display: flex;

      @media (max-width: 479.8px) {
        flex-direction: column;
        height: $bottomnav-collapsed-height;
        padding-top: 12px;
        padding-bottom: 4px;

        .other-platform {
          display: none;
        }

        span {
          font-size: 14px;
          line-height: 18px;
        }

        .sidebar-item-icon {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          ;

          i {
            font-size: 32px;
            color: #1094ce;
          }

          .svg-icon {
            svg {
              width: 32px;
            }
          }
        }
      }

      @media (min-width: 480px) {
        flex-direction: row;
        height: 64px;
        align-items: center;
        width: $sidebar-width;

        .mobile-platform {
          display: none;
        }

        .sidebar-item-icon {
          width: 80px;
          display: flex;
          justify-content: center;

          i {
            font-size: 32px;
            color: #1094ce;
          }

          .svg-icon {
            svg {
              width: 32px;
            }
          }
        }
      }
    }

    .nav-link-icon {
      align-items: center;

      @media (min-width: 480px) {
        align-items: flex-start;
      }
    }
  }
}

.custom-ui {
  padding: 1rem;

  .confirm-alert-actions {
    @media (max-width: 640px) {
      flex-direction: column;
      row-gap: 1rem;
    }
  }
}