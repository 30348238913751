@import "../../assets/scss/custom/variables.scss";

.availability {
  margin-top: 32px;
  width: 100%;

  th {
    border: 1px solid rgb(190, 189, 189);
    background-color: lightgray;
  }

  td {
    width: 100px;
    height: 60px;
    border: 1px solid green;
    text-align: center;

    &:hover {
      background: #EFEFEF;
    }
  }

  td.available-cell {
    cursor: pointer;

    &.selected {
      background: $blue-secondary;
      border-color: $blue-secondary;
    }

    &.low-availability {
      background-color: #ffcccb;
    }

    &.medium-availability {
      background-color: #ffb347;
    }

    &.high-availability {
      background-color: #77dd77;
    }

    &.individual-host-available {
      background-color: $blue-secondary;
    }
  }

  thead tr {
    height: 48px;
  }

  tfoot tr td {
    border: 0;
    padding-top: 10px;
  }
}

.head-time {
  font-size: 16px;
  font-weight: bold;
  color: black;
  text-align: center;
  white-space: nowrap;
}

.head-weekday {
  width: 64px;
  padding-right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: right;
}

.foot-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;

  .low-availability-ex,
  .medium-availability-ex,
  .high-availability-ex,
  .selected-ex,
  .unselected-ex,
  .individual-host-available-ex,
  .individual-host-unavailable-ex,
  .host-not-available,
  .host-available,
  .host-booked,
  .host-tentative-booked,
  .host-provider-booked {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 1px solid gray;
  }

  .medium-availability-ex,
  .high-availability-ex,
  .host-available,
  .host-booked,
  .host-tentative-booked,
  .host-provider-booked {
    margin-left: 16px; // Add left padding here
  }

  .low-availability-ex {
    background-color: #ffcccb;
  }

  .medium-availability-ex {
    background-color: #ffb347;
  }

  .high-availability-ex {
    background-color: #77dd77;
  }

  .selected-ex,
  .individual-host-available-ex {
    background: $blue-secondary;
  }

  .unselected-ex,
  .individual-host-unavailable-ex {
    background: white;
  }

  // Specific date view legend
  .host-not-available {
    background-color: #e0e0e0;  // Grey
  }

  .host-available {
    background-color: #77dd77;  // Green
  }

  .host-booked {
    background-color: #ffb347;  // Orange (for hosting)
  }
  
  .host-tentative-booked {
    background-color: #fff176;  // Yellow for tentative assignments
  }

  .host-provider-booked {
    background-color: #ff6b6b;  // Red for provider bookings
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  .gap {
    width: 40px;
  }
}

.page-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  
  .page-title {
    flex: 1;
    
    .title-with-refresh {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      
      h1 {
        margin-bottom: 0;
        margin-right: auto;
      }
      
      .refresh-button {
        margin-left: 10px;
      }
    }
  }

  .page-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.individual-host-available-ex {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: $blue-secondary;
  border: 1px solid gray;
}

.individual-host-unavailable-ex {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-right: 8px;
  background: white;
  border: 1px solid gray;
}

.host-tooltip {
  text-align: left;
  
  ul {
    margin: 5px 0 0 0;
    padding-left: 20px;
    
    li {
      margin-bottom: 2px;
    }
  }
}

// Make cells with tooltips have a pointer cursor
.low-availability, .medium-availability, .high-availability {
  cursor: pointer;
}

// Date picker styles
.date-select {
  margin-bottom: 20px;

  .MuiFormControl-root {
    width: 100%;
  }
  
  .date-picker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .MuiInputBase-root, .form-control {
      flex: 1;
    }
    
    .clear-date-btn {
      margin-left: 10px;
      white-space: nowrap;
      height: 38px;
    }
  }
}

// Specific date view styles
.date-weekday {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  margin-top: 4px;
}

// Host availability status colors for specific date view
.host-not-available {
  background-color: #e0e0e0;  // Grey
}

.host-available {
  background-color: #77dd77;  // Green
}

.host-booked {
  background-color: #ffb347;  // Orange (for hosting)
  position: relative;
  
  .fa-calendar {
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    transition: transform 0.2s, color 0.2s;
    
    &:hover {
      color: #333;
      transform: scale(1.2);
    }
  }
}

// Styling for tentative bookings (from dropdown selection)
.host-tentative-booked {
  background-color: #fff176;  // Yellow for tentative assignments
  position: relative;
  
  .fa-calendar {
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    transition: transform 0.2s, color 0.2s;
    
    &:hover {
      color: #333;
      transform: scale(1.2);
    }
  }
}

// Special styling for when a host is booked as a provider
.host-provider-booked {
  background-color: #ff6b6b;  // Red for provider bookings
  position: relative;
  
  .fa-calendar {
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    transition: transform 0.2s, color 0.2s;
    
    &:hover {
      color: #333;
      transform: scale(1.2);
    }
  }
}

// For partial provider bookings
.host-provider-partial {
  position: relative;
  overflow: hidden;
  
  // For partial start (session starts in middle of hour)
  &.partial-start {
    background-color: #77dd77; // Green background for available time
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: var(--partial-start, 0%);
      right: 0;
      background-color: #ff6b6b; // Red background for provider time
      z-index: 0;
    }
  }
  
  // For partial end (ends mid-hour)
  &:not(.partial-start) {
    background-color: #ff6b6b; // Red background for provider time
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: var(--partial-width, 50%);
      right: 0;
      background-color: #77dd77; // Green background for available time
      z-index: 0;
    }
  }
  
  // For extended sessions in unavailable hours
  &.host-not-available-extended {
    background-color: #ddd; // Gray background
    
    &::before {
      background: linear-gradient(to right, #ff6b6b var(--partial-width, 50%), #ddd var(--partial-width, 50%));
      z-index: 0;
    }
  }
  
  // For single-hour bookings (session starts and ends within the same hour)
  &.host-partial-single-hour {
    background-color: #77dd77; // Green background for available time
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: var(--partial-start, 0%);
      width: var(--partial-width, 50%);
      background-color: #ff6b6b; // Red background for provider time
      z-index: 0;
    }
  }
  
  .fa-calendar {
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    transition: transform 0.2s, color 0.2s;
    
    &:hover {
      color: #333;
      transform: scale(1.2);
    }
  }
}

.host-partial-booked {
  position: relative;
  overflow: hidden;
  
  // Create a gradient background
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #ffb347 var(--partial-width, 50%), #77dd77 var(--partial-width, 50%));
    z-index: 0;
  }
  
  // For partial start (session starts in middle of hour)
  &.partial-start::before {
    background: linear-gradient(
      to right, 
      #77dd77 0%, 
      #77dd77 var(--partial-start, 0%), 
      #ffb347 var(--partial-start, 0%), 
      #ffb347 100%
    );
  }
  
  // When the host has a tentative assignment
  &.host-tentative-partial::before {
    background: linear-gradient(to right, #fff176 var(--partial-width, 50%), #77dd77 var(--partial-width, 50%));
  }
  
  // When the host has a tentative assignment starting in middle of hour
  &.host-tentative-partial.partial-start::before {
    background: linear-gradient(
      to right, 
      #77dd77 0%, 
      #77dd77 var(--partial-start, 0%), 
      #fff176 var(--partial-start, 0%), 
      #fff176 100%
    );
  }
  
  // When the host is also a provider (delivering)
  &.host-provider-partial::before {
    background: linear-gradient(to right, #ff6b6b var(--partial-width, 50%), #77dd77 var(--partial-width, 50%));
  }
  
  // When the host is also a provider with session starting in middle of hour
  &.host-provider-partial.partial-start::before {
    background: linear-gradient(
      to right, 
      #77dd77 0%, 
      #77dd77 var(--partial-start, 0%), 
      #ff6b6b var(--partial-start, 0%), 
      #ff6b6b 100%
    );
  }
  
  // For extended sessions in unavailable hours
  &.host-not-available-extended {
    background-color: #ddd; // Gray background
    
    &::before {
      background: linear-gradient(to right, #ffb347 var(--partial-width, 50%), #ddd var(--partial-width, 50%));
      z-index: 0;
    }
  }
  
  // For extended provider sessions in unavailable hours
  &.host-provider-partial.host-not-available-extended::before {
    background: linear-gradient(to right, #ff6b6b var(--partial-width, 50%), #ddd var(--partial-width, 50%));
  }
  
  .fa-calendar {
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    transition: transform 0.2s, color 0.2s;
    
    &:hover {
      color: #333;
      transform: scale(1.2);
    }
  }
}

// For sessions that start and end within the same hour
.host-partial-single-hour {
  position: relative;
  overflow: hidden;
  background-color: #77dd77; // Green background for available time
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--partial-start, 0%);
    width: var(--partial-width, 50%);
    background-color: #ffb347; // Orange background for hosted time
    z-index: 0;
  }
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--partial-start2, null);
    width: var(--partial-width2, null);
    background-color: #ffb347; // Orange background for second booking
    z-index: 0;
    display: var(--display-second, none); // Only show if there's a second booking
  }
  
  // When the host is also a provider (delivering)
  &.host-provider-partial::before {
    background-color: #ff6b6b; // Red background for provider time
  }
  
  // When the host is also a provider for second booking
  &.host-provider-partial::after {
    background-color: #ff6b6b; // Red background for provider time
  }
  
  // When the host has a tentative assignment
  &.host-tentative-partial::before {
    background-color: #fff176; // Yellow background for tentative time
  }
  
  // When the host has a second tentative assignment in the same hour
  &.host-tentative-partial::after {
    background-color: #fff176; // Yellow background for tentative time
  }
  
  .fa-calendar {
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    transition: transform 0.2s, color 0.2s;
    
    &:hover {
      color: #333;
      transform: scale(1.2);
    }
  }
}

// For sessions where the host is a provider that start and end within the same hour
.host-partial-single-hour-provider {
  position: relative;
  overflow: hidden;
  background-color: #77dd77; // Green background for available time
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--partial-start, 0%);
    width: var(--partial-width, 50%);
    background-color: #ff6b6b; // Red background for provider time
    z-index: 0;
  }
  
  .fa-calendar {
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    transition: transform 0.2s, color 0.2s;
    
    &:hover {
      color: #333;
      transform: scale(1.2);
    }
  }
}

.host-name-cell {
  text-align: left;
  padding-left: 10px;
  font-weight: normal;
  width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.specific-date-heading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

// Legend calendar icon
.legend-calendar, .legend-history {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.legend-history i {
  color: #444444;
  font-size: 14px;
}

// Tooltip styling
.session-tooltip {
  font-size: 14px;
  padding: 4px 8px;
  max-width: 300px;
}

// Session table styles
.sessions-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 15px;
  
  .sessions-table-title {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
    border-bottom: 2px solid #007bff;
    padding-bottom: 8px;
    display: inline-block;
  }
  
  .auto-assign-btn {
    padding: 8px 16px;
    font-weight: 600;
  }
}

.school-lead-title {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #555;
  background-color: #f0f0f0;
  padding: 8px 15px;
  border-left: 4px solid #007bff;
  border-radius: 0 4px 4px 0;
}

table.sessions {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  border-collapse: collapse;
  
  th, td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f5f5f5;
    font-weight: 600;
  }
  
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  // Animation for assigned rows
  .assigned-session-row {
    animation: fadeOut 3s forwards;
    opacity: 1;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
      background-color: #d4edda; // Light green success background
    }
    60% {
      opacity: 1;
      background-color: #d4edda;
    }
    100% {
      opacity: 0.3;
      background-color: #f8f9fa; // Very light gray
    }
  }
  
  .host-select {
    min-width: 200px;
  }
  
  .host-assign-container {
    display: flex;
    align-items: center;
    
    .host-select {
      flex-grow: 1;
      margin-right: 8px;
      
      .select-option-with-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .fa-history {
          color: #28a745;
          margin-left: 8px;
          font-size: 14px;
        }
      }
    }
    
    .assign-host-btn {
      flex-shrink: 0;
      border-radius: 4px;
      padding: 6px 10px;
      margin-left: 5px;
      
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  
  .session-title {
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .session-provider {
    font-size: 0.85rem;
    color: #666;
    font-style: italic;
  }
  
  .school-name {
    font-weight: 600;
    margin-bottom: 4px;
  }
    
  th, td {
    &:nth-child(1) {
      width: 35%;
    }
    
    &:nth-child(2) {
      width: 32%;
    }
    
    &:nth-child(3) {
      width: 18%;
    }
    
    &:nth-child(4) {
      width: 15%;
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: 20px 0;
  background-color: #f8f9fa;
  border-radius: 8px;
  
  i {
    font-size: 24px;
    color: $blue-secondary;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    color: #6c757d;
    margin: 0;
  }
}

// New styles for the availability schedule component
.week-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  
  @media (max-width: 767px) {
    padding: 5px;
    flex-wrap: wrap;
  }
  
  .nav-button {
    padding: 6px 10px;
    margin: 0 10px;
    border-radius: 4px;
    
    &:hover:not(:disabled) {
      background-color: #e9ecef;
    }
    
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  
  .week-display {
    font-size: 16px;
    font-weight: 500;
    min-width: 200px;
    text-align: center;
    
    @media (max-width: 767px) {
      min-width: 140px;
      font-size: 14px;
      margin: 5px 0;
    }
    
    .current-week-badge {
      background-color: #28a745;
      color: white;
      font-size: 12px;
      padding: 2px 6px;
      border-radius: 10px;
      white-space: nowrap;
    }
  }
}

.timezone-info {
  margin-bottom: 15px;
  font-size: 14px;
  color: #666;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  
  @media (max-width: 767px) {
    padding: 8px;
    text-align: center;
  }
  
  p {
    margin-bottom: 5px;
  }
  
  .timezone-selector {
    .timezone-select {
      width: 220px;
      min-width: 220px;
      
      .react-select__control {
        min-height: 32px;
        height: 32px;
        
        .react-select__value-container {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  
  .timezone-note {
    color: #007bff;
    font-style: italic;
  }
}

// Grid improvements for the new scheduler
.sh-availability {
  .availability-grid {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    
    .days-header {
      display: flex;
      background-color: #f5f5f5;
      border-bottom: 1px solid #ddd;
      
      .time-label {
        width: 80px;
        border-right: 1px solid #ddd;
        padding: 10px;
        
        @media (max-width: 767px) {
          width: 40px;
          padding: 5px;
        }
      }
      
      .day-label {
        flex: 1;
        padding: 10px;
        text-align: center;
        border-right: 1px solid #ddd;
        min-width: 120px; /* Ensure enough room for full weekday and month names */
        
        @media (max-width: 767px) {
          min-width: 40px; /* Smaller width on mobile */
          padding: 5px;
        }
        
        &:last-child {
          border-right: none;
        }
        
        .day-name {
          font-weight: bold;
          margin-bottom: 5px;
          font-size: 14px; /* Adjust font size for longer day names */
          white-space: nowrap; /* Prevent wrapping of day names */
          
          .mobile-day {
            display: none; /* Hide on desktop */
          }
          
          @media (max-width: 767px) {
            .desktop-day {
              display: none; /* Hide on mobile */
            }
            
            .mobile-day {
              display: inline; /* Show on mobile */
              font-size: 16px; /* Larger font for single letters */
            }
          }
        }
        
        .date {
          font-size: 12px;
          color: #666;
          white-space: nowrap; /* Prevent wrapping of the date */
          
          .mobile-date {
            display: none; /* Hide on desktop */
          }
          
          @media (max-width: 767px) {
            .desktop-date {
              display: none; /* Hide on mobile */
            }
            
            .mobile-date {
              display: inline; /* Show on mobile */
            }
          }
        }
      }
    }
    
    .time-slots {
      .time-row {
        display: flex;
        border-bottom: 1px solid #ddd;
        
        &:last-child {
          border-bottom: none;
        }
        
        .time-label {
          width: 80px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f9f9f9;
          border-right: 1px solid #ddd;
          
          @media (max-width: 767px) {
            width: 40px;
            padding: 5px;
            font-size: 12px;
          }
          
          .am-pm {
            font-size: 12px;
            margin-left: 2px;
            color: #666;
          }
        }
        
        .time-block {
          flex: 1;
          height: 60px;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: center;
          
          @media (max-width: 767px) {
            height: 45px;
            min-width: 40px;
          }
          
          &:last-child {
            border-right: none;
          }
          
          &.available {
            background-color: $blue-secondary;
          }
          
          &.clickable {
            cursor: pointer;
            
            &:hover {
              opacity: 0.8;
            }
          }
          
          .count {
            font-weight: bold;
            color: white;
          }
        }
      }
    }
  }
  
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    
    .save-button {
      padding: 8px 20px;
      font-weight: 500;
    }
  }
}