.dropdownToggle {
    margin: 0 !important;
}

a {
    .school-tag {
        text-decoration: underline;
        color: #0f6aa8 !important;
    }
}

.teacher-tag {
    text-decoration: underline;
    color: #0f6aa8 !important;
}

.wish-list {
    background-color: #ffcccb !important;
}

.session-card-container {
    display: flex;
    flex-direction: column;
    width: 100%
}

.img-pr {
    padding-right: 5px;
}

.token-blue {
    width: 28px;
    height: 28px;
    margin-right: 8px;
}

.btn-favourite {
    border-radius: 50% !important;
    text-align: center !important;
    background-color: white !important;
    box-shadow: none !important;
    border: none !important;
    margin-right: 0 !important;
    padding: 0 5px;
    height: 40px;
}

.session-actions {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.btn-supplies-receipt {
  background-color: #a8e0b0 !important; // Light green background
  border-color: #a8e0b0 !important;
  color: #333 !important;
  transition: all 0.3s ease;
  
  &:hover, &:focus {
    background-color: #74c782 !important; // Darker green on hover
    border-color: #74c782 !important;
    color: #fff !important;
  }
}

.btn-student-survey {
  background-color: #a3c9f4 !important; // Light blue background
  border-color: #a3c9f4 !important;
  color: #333 !important;
  transition: all 0.3s ease;
  
  &:hover, &:focus {
    background-color: #6b9fe4 !important; // Darker blue on hover
    border-color: #6b9fe4 !important;
    color: #fff !important;
  }
}

@media (max-width: 768px) {
  .session-actions {
    flex-direction: column;
    
    .propose-alternates-btn {
      order: 3; // This will move it after both confirm buttons
    }
  }
}