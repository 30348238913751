.min-body {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-teacher,
.st-customize-msg {
  margin-top: 20px;
}

.no-margin {
  margin: 0 !important;
}

.st-customize-msg {
  .message {
    height: 100px;
  }
}

.matched-teachers-selector {
  margin-bottom: 30px;
}

.filter-options {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  .filter-by-region,
  .filter-by-timezone,
  .filter-by-grade {
    flex: 1;
  }
}

.select-teacher-option {
  cursor: default;
  display: block;
  width: 100%;
  user-select: none;
  background-color: transparent;
  color: inherit;
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    font-size: 14px;
  }

  &:hover {
    background-color: #deebff;
  }

  &:active {
    background-color: #B2D4FF;
  }

  span.is-sent {
    color: red;
  }
}